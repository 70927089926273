import React from "react"
import Container from "../components/container"

export default class About extends React.Component {
  render() {
    return(
      <Container>
        <section className={`hero is-medium is-primary is-bold`}>
          <div className={`hero-body`}>
            <div className={`container has-text-centered`}>
              <h1 className={`title`}>About Me</h1>
            </div>
          </div>
        </section>
      </Container>
    )
  }
}